import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Box } from "@mui/material";

import "./smooth_loader.css";
import { useState } from "react";
import { useEffect } from "react";

export default function LoadingBackdrop({
  open,
  dontOpenLate = false,
  handleClose,
  sxProps = {},
}) {
  const [openLate, setOpenLate] = useState(dontOpenLate);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpenLate(true);
      }, 2000);
    } else {
      setOpenLate(false);
    }
  }, [open]);

  return (
    <Backdrop
      sx={{
        bgcolor: "rgba(0,0,0,0.15)",
        transition: "unset",
        zIndex: 99999999, //(theme) => theme.zIndex.drawer + 1,
        ...sxProps,
      }}
      open={open && openLate}
      onClick={handleClose}
    >
      <Box sx={{ width: "100%", height: "200px" }}>
        <div className="wrapper-bloader">
          <Box
            component={"span"}
            sx={{
              opacity: { xs: "0", sm: "1", md: "1" },
            }}
            className="circle-bload circle-bload-1"
          ></Box>

          <span className="circle-bload circle-bload-2"></span>
          <span className="circle-bload circle-bload-3"></span>
          <span className="circle-bload circle-bload-4"></span>
          <span className="circle-bload circle-bload-5"></span>
          <span className="circle-bload circle-bload-6"></span>
          <span className="circle-bload circle-bload-7"></span>

          <Box
            component={"span"}
            sx={{
              opacity: { xs: "0", sm: "1", md: "1" },
            }}
            className="circle-bload circle-bload-8"
          ></Box>
        </div>
      </Box>
    </Backdrop>
  );
}
