import "./App.css";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import coreThemeFile from "./Resources/Design/SourcesJS/themes";
import React, { useEffect, useState, createContext, useMemo } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import BaseFooter from "./Resources/Core/Footer/BaseFooter";
import SavedToast from "./Resources/Core/Feedback/SavedToast";
import ScrollUpComp from "./Resources/Core/Base/ScrollUpComp";
import publicThemeFile from "./Resources/Design/SourcesJS/publicTheme";
import LoadingBackdrop from "./Resources/Core/Layouts/LoadingBackdrop";
import { getBlogBaseNewest } from "./Resources/Public/Pages/Blog/Firebase/BlogControls";
import Helmet from "./Resources/Routes/Helmet/Helmet";
import ErrorHandle from "./Resources/Routes/ErrorHandle/ErrorHandle";
import axios from "axios";
import CookieBar from "./Resources/Routes/Legal/CookieBar";
import { setAnalyticsConsent } from "./Resources/Firebase/init";

export const getPricesFromApi = async () => {
  return new Promise(async (res, rej) => {
    axios
      .get(
        "https://europe-west1-core-profaktura.cloudfunctions.net/api/getPrices"
      )
      .then((result) => {
        return res(result.data);
      })
      .catch((error) => {
        throw rej(error);
      });
  });
};

export const theme = createTheme(coreThemeFile());
export const publicTheme = createTheme(publicThemeFile());

export const ArticlesContext = createContext();
export const CenikContext = createContext();

export const PLATCI_DPH = false;

function App(props) {
  const { isAdmin } = props;

  const [loading, setLoading] = useState(true);
  const [fireAuth, setFireAuth] = useState(null);
  const [baseArticles, setBaseArticles] = useState(null);
  const [cenikData, setCenikData] = useState({});

  const location = useLocation();

  useEffect(() => {
    if (!isAdmin) {
      setAnalyticsConsent();
    }
  }, []);

  function showSucces() {
    if (!location?.state?.success) return false;
    return true;
  }

  function isBlogOrSupp() {
    return location?.pathname === "/blog" || location?.pathname === "/podpora";
  }

  const isAdminLogged = () => {
    return Boolean(fireAuth);
  };

  useEffect(() => {
    if (location.pathname === "/cenik") {
      const getData = async () => {
        try {
          console.log("GetData");
          const data = await getPricesFromApi();
          setCenikData(data);
        } catch (error) {
          console.log("GetData error", error);
          throw error;
        }
      };

      getData().catch((error) => {
        window.open("https://app.profaktura.cz/plany", "_self");
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isAdmin) return;
    const loadAuth = async () => {
      const { auth } = await import("./Resources/Firebase/user/auth");
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          setFireAuth(user);
        } else {
          setFireAuth(false);
        }
        setLoading(false);
      });
    };

    loadAuth();
  }, [isAdmin]);

  useEffect(() => {
    if (!baseArticles && !isAdmin && !isBlogOrSupp()) {
      getBlogBaseNewest()
        .then((result) => {
          console.log("Articles ", result);
          setBaseArticles(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const OutletContainer = ({ admin = false }) => {
    return (
      <Helmet>
        <ThemeProvider theme={publicTheme}>
          <CssBaseline />
          {showSucces() && <SavedToast />}
          {!admin && <CookieBar />}
          <div
            className="App"
            style={{ backgroundColor: "#FBFBFB" /*FBFBFB */, height: "100%" }}
          >
            <ScrollUpComp />
            <div style={{ minHeight: "100vh" }}>
              <Outlet />
            </div>
            <BaseFooter extended admin={admin} />
          </div>
        </ThemeProvider>
      </Helmet>
    );
  };

  const AppBase = () => {
    if (isAdmin) {
      if (loading) {
        return <LoadingBackdrop open={true} />;
      }

      if (location.pathname == "/login" || isAdminLogged()) {
        return <OutletContainer admin={true} />;
      } else {
        return <Navigate to="/login" />;
      }
    } else if (!isAdmin) {
      return (
        <CenikContext.Provider value={cenikData}>
          <ArticlesContext.Provider
            value={{ data: baseArticles, setData: setBaseArticles }}
          >
            <OutletContainer />
          </ArticlesContext.Provider>
        </CenikContext.Provider>
      );
    }
  };

  return useMemo(() => (
    <ErrorHandle>
      <AppBase />
    </ErrorHandle>
  ));
}

export default App;
