import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
  setConsent,
} from "firebase/analytics";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { areCookiesAccepted } from "../Routes/Legal/CookieBar";

console.log("Core load");

// Public
const firebaseConfig = {
  apiKey: "AIzaSyD301Kpa2-OzP1JfW4sEUblmYFEPNrN8_w",
  authDomain: "profaktura.cz",
  projectId: "app-profaktura",
  storageBucket: "app-profaktura.appspot.com",
  messagingSenderId: "35978105951",
  appId: "1:35978105951:web:527e4c27d2b593184c37ea",
  measurementId: "G-3KPLDX8RKL",
};

// Console
const coreFirebaseConfig = {
  apiKey: "AIzaSyCQIdD-VWQfM73b7ENpe3LkZvZ0-pKXHyw",
  authDomain: "app.profaktura.cz",
  databaseURL:
    "https://core-profaktura-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "core-profaktura",
  storageBucket: "core-profaktura.appspot.com",
  messagingSenderId: "849698345234",
  appId: "1:849698345234:web:61b3d1b169f954692123cb",
  measurementId: "G-R0NG0L4XRZ",
};

export const core = initializeApp(coreFirebaseConfig, "core");

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// ANALYTICS
const analytics = getAnalytics(core);

setAnalyticsCollectionEnabled(analytics, true);

export function logAnalytics(name, additional_params = {}) {
  if (Object.keys(additional_params).length === 0) {
    logEvent(analytics, name);
  } else {
    logEvent(analytics, name, additional_params);
  }
}

// Combine analytics
if (window?.gtag) {
  window.gtag("config", "G-R0NG0L4XRZ", {
    linker: {
      domains: ["app.profaktura.cz", "profaktura.cz"],
    },
  });
}

// Full default
const fullConsent = {
  ad_storage: "granted",
  ad_user_data: "granted",
  ad_personalization: "granted",
  analytics_storage: "granted",
  functionality_storage: "granted",
  personalization_storage: "granted",
  security_storage: "granted",
};

// Consent Required Always
const partialConsent = {
  ad_storage: "denied",
  ad_user_data: "denied",
  ad_personalization: "denied",
  analytics_storage: "denied",
  functionality_storage: "granted",
  personalization_storage: "granted",
  security_storage: "granted",
};

function sklikAnonymousRetargeting(allowed = false) {
  if (!window?.rc?.retargetingHit) return;
  var retargetingConf = {
    rtgId: 86687,
    consent: allowed ? 1 : 0,
  };
  window.rc.retargetingHit(retargetingConf);
}

export function setDefaultConsent() {
  sklikAnonymousRetargeting(false);
  setConsent(partialConsent);
  if (window?.gtag) {
    window.gtag("consent", partialConsent);
  }
  if (window?.fbq) {
    window.fbq("consent", "revoke");
  }
}

export function setFullConsent() {
  sklikAnonymousRetargeting(true);
  setConsent(fullConsent);
  if (window?.gtag) {
    window.gtag("consent", fullConsent);
  }
  if (window?.fbq) {
    window.fbq("consent", "grant");
  }
}

export function setAnalyticsConsent() {
  if (!areCookiesAccepted()) {
    setDefaultConsent();
  } else {
    setFullConsent();
  }
}

// FUNCTIONS
const functions = getFunctions(app, "europe-west1");

export const EMULATOR_STARTED = true;

if (process.env.NODE_ENV === "development" && EMULATOR_STARTED) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5005);
}

const callableOptions = {
  limitedUseAppCheckTokens: true,
};
const initCallFunction = (name, options = {}) =>
  httpsCallable(functions, name, {
    ...callableOptions,
    ...options,
  });

export const sendSupportForm = initCallFunction("sendSupportForm");
